import * as common from './common';
import * as payment from './payment';
import * as auth from './authentication';
import * as nmi from './Connectors/CardNmi';
import * as rapyd from './Connectors/CardRapyd';
import * as wp from './Connectors/worldPay';
import * as config from '../utils/config';

import type {  EditPaymentRequest } from './payment';
import type { Styling } from './Connectors/CardRapyd';
import type { CollectJSConfig, NewCardRequest } from './Connectors/CardNmi';

export interface CheckoutSessionDetails {
    uuid: string;
    orderToken: string;
    apiBaseUrl: string;
}

// auth
const init = async (CheckoutSessionDetails: CheckoutSessionDetails) => {
    try {
        if(auth.getToken() && await common.getOrderDetails()) {
            return 'PAYMENT_SESSION_ALREADY_IN_PROGRESS';
        }
        if (!CheckoutSessionDetails.apiBaseUrl) {
            throw new Error('apiBaseUrl is required');
        }

        config.setConfig({
            apiBaseUrl: CheckoutSessionDetails.apiBaseUrl
        });
        await auth.setOrderToken(CheckoutSessionDetails.orderToken);
        const isLoggedIn = await auth.login({ uuid: CheckoutSessionDetails.uuid });
        const isOrderSucess = await common.getOrderDetails();
        await common.assignOrder();
        const duplicateResult = await common.checkForDuplicate();
        return {
            success: (isLoggedIn && isOrderSucess) ? true : false,
            isDuplicate: duplicateResult
        };
    } catch (error) {
        destroySession();
        throw error;
    }
};

const destroySession = () => { 
    auth.killSession();
    common.resetCommon();
    payment.resetPayment();
    wp.destroyWorldpay();
};

const sessionUserDetails = () => {
    return auth.getUserDetails();
};


// payment
const paymentInstruments = (filters: payment.PaymentInstrumentsFilters) => {
    return payment.getPaymentInstruments(filters);
};

const submitPayment = (details: payment.PaymentDetails) => {
    return payment.submitPayment(details);
};

// common
const getOrderDetails = () => {
    return common.getOrderDetails();
};

const cancelPayment = () => {
    return common.cancelPaymentOrder();
};

const getWalletAccounts = () => {
    return common.getWallets();
};

const payquickerBalance = () => {
    return common.getPayquickerBalance();
};

const availableConnectors = () => {
    return common.getPaymentConnectors();
};

// connectors
const addWorldPayCard = async(options: wp.WorldPayOptions) => {
    const orderDetails = await common.getOrderDetails();
    return wp.addWorldPayCard(orderDetails.currency, options);
};

const getAccessToken = async () => {
    return auth.getToken();
};

const setAccessToken =async (token: string) => {
    return await auth.setToken(token);
};

const updatePaymentInstrument = async(requestBody: EditPaymentRequest) => {
    return await payment.updatePaymentInstrument(requestBody);
};

const loadNMICard = async (params: CollectJSConfig) => {
    return await nmi.loadNMICard(params);
};

const loadRapydframe = async(params: Styling) => {
    rapyd.loadRapydframe(params);
};

const addNewCard = async (params: NewCardRequest) => {
    if(params.cardType === 'NMI') {
        nmi.addNMICard(params);
    }
};

const experimental = {
    addWorldPayCard,
    payquickerBalance,
    restoreSession: async (session: { apiBaseUrl: string, sessionToken: string, orderToken: string, orderDetails: common.OrderDetails }) => {
        config.setConfig({
            apiBaseUrl: session.apiBaseUrl
        });
        auth.setToken(session.sessionToken);
        auth.setOrderToken(session.orderToken);
        common.setOrderDetails(session.orderDetails);
        return {
            success: true,
            isDuplicate: false
        };
    },
    getSession: async () => {
        return {
            apiBaseUrl: config.getConfig().apiBaseUrl,
            sessionToken: auth.getToken(),
            orderToken: auth.getOrderToken(),
            orderDetails: await common.getOrderDetails()
        };
    },
    setBaseUrl:async (apiBaseUrl: string) => {
        config.setConfig({
            apiBaseUrl: apiBaseUrl
        });
    },
    setOrderToken:async (orderToken: string) => {
        auth.setOrderToken(orderToken);
    }
};

export { 
    init, // for getting user session 
    destroySession, //for destroying the user session
    sessionUserDetails, //for retriving user Details
    paymentInstruments, //fetching Payment Instruments
    getOrderDetails, //Getting current session Order Details
    getWalletAccounts, //gettting wallet accounts.
    availableConnectors, //get Available Connectors for the order
    submitPayment, //Submit Payment
    cancelPayment, //cancel payment order
    experimental, //experimental features,
    getAccessToken,
    setAccessToken,
    loadNMICard,
    updatePaymentInstrument,
    addNewCard,
    loadRapydframe
};
