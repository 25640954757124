import * as API from './api/index';
import type { 
    UserDetails
} from './api/authentication';
import type { 
    PaymentInstrument,
    EditPaymentRequest,
    PaymentInstrumentsFilters,
    PaymentDetails 
} from './api/payment';
import type {
    OrderDetails,
    WalletAccount,
    FormattedConnector as PaymentConnector,
    CancelResponse
} from './api/common';
import type { Styling } from './api/Connectors/CardRapyd';
import type { CollectJSConfig, NewCardRequest } from './api/Connectors/CardNmi';

interface CheckoutCustomResponse {
    message: string;
    redirectUrl?: string;
}

export interface CheckoutSessionDetails {
    uuid: string;
    orderToken: string;
    apiBaseUrl: string;
}

export interface CheckoutSessionResponse {
    success: boolean;
    isDuplicate: boolean | undefined;
}

export interface UWallet {
    initSession: (details: CheckoutSessionDetails) => Promise<CheckoutSessionResponse | 'PAYMENT_SESSION_ALREADY_IN_PROGRESS'>;
    destroySession: () => void;
    getUserDetails: () => Promise<UserDetails>;
    getPaymentInstruments: (filters: PaymentInstrumentsFilters) => Promise<PaymentInstrument[]>;
    getOrderDetails: () => Promise<OrderDetails>;
    getWalletAccounts: () => Promise<WalletAccount[]>;
    getAvailableConnectors: () => Promise<PaymentConnector[]>;
    submitPayment: (details: PaymentDetails) => Promise<CheckoutCustomResponse>;
    cancelPayment: () => Promise<CancelResponse>;
    experimental: { [key: string]: any };
    //For temproary Usage======
    getAccessToken: () => Promise<string | null>;
    setAccessToken: (token: string) => Promise<boolean>;
    updatePaymentInstrument: (requestBoydy: EditPaymentRequest) => Promise<string>;
    loadNMICard: (params: CollectJSConfig) => void;
    addNewCard: (params: NewCardRequest) => void;
    loadRapydframe: (params: Styling) => void;
}

/**
 * uWallet SDK - Payment processing module
 * @module uWallet
 */

const uWallet: UWallet = {
    /**
     * Initialize a payment session
     * @param {Object} details - Session initialization details
     * @param {string} details.uuid - User's UUID
     * @param {string} details.orderToken - Order token for the payment session
     * @param {string} details.apiBaseUrl - Base URL for the API
     * @returns {Promise<'PAYMENT_SESSION_ALREADY_IN_PROGRESS' | { success: boolean, isDuplicate: boolean }>}
     */
    initSession: API.init,

    /**
     * Destroy the current payment session and cleanup resources
     * @returns {void}
     */
    destroySession: API.destroySession,

    /**
     * Get details of the currently logged in user
     * @returns {Promise<UserDetails>}
     */
    getUserDetails: API.sessionUserDetails,

    /**
     * Get available payment instruments for the current user
     * @param {Object} filters - Filter criteria
     * @param {('CARD'|'BANK')} filters.type - Type of payment instruments to retrieve
     * @returns {Promise<PaymentInstrument[]>}
     */
    getPaymentInstruments: API.paymentInstruments,

    /**
     * Get available wallet accounts for the current order
     * @returns {Promise<WalletAccount[]>}
     */
    getWalletAccounts: API.getWalletAccounts,

    /**
     * Get details of the current order
     * @returns {Promise<OrderDetails>}
     */
    getOrderDetails: API.getOrderDetails,

    /**
     * Get available payment connectors for the current order
     * @returns {Promise<PaymentConnector[]>}
     */
    getAvailableConnectors: API.availableConnectors,

    /**
     * Submit a payment for processing
     * @param {PaymentDetails} details - Payment details including payment method and amounts
     * @returns {Promise<CheckoutCustomResponse>}
     */
    submitPayment: API.submitPayment,

    /**
     * Cancel the current payment order
     * @returns {Promise<CancelResponse>}
     */
    cancelPayment: API.cancelPayment,

    /**
     * Experimental features
     * @returns {any}
     */
    experimental: API.experimental,

    /**
     * For getting the access token to use in merchant Checkout web app
    */
    getAccessToken: API.getAccessToken,

    setAccessToken: API.setAccessToken,

    /**
     * Submit a payment for processing
     * @param {EditPaymentRequest} details - Payment details including payment method and amounts
     * @returns {Promise<string>}
     */
    updatePaymentInstrument: API.updatePaymentInstrument,

    loadNMICard: API.loadNMICard,

    loadRapydframe: API.loadRapydframe,

    addNewCard: API.addNewCard,


};

declare global {
    interface Window {
        uWallet: UWallet;
    }
}

if (typeof window !== 'undefined') {
    window.uWallet = uWallet;
}

export default uWallet;
export type {
    UserDetails,
    PaymentInstrument,
    PaymentDetails,
    OrderDetails,
    WalletAccount,
    PaymentConnector
};
