import * as common from './common';
import * as config from '../utils/config';
import * as auth from './authentication';
import * as httpClient from '../utils/httpClient';
import * as wpConnector from './Connectors/worldPay';
import * as tossConnector from './Connectors/TossPay';
import { FormattedConnector } from './common';
import { getWalletAccounts } from './index';

export interface PaymentInstrument {
    type: 'BANK' | 'CARD'; // TODO: Verify this is correct
    account_reference?: string;
    accountName?: string;
    accountType?: string;
    address?: string;
    address2?: string;
    availableBalance?: number;
    bank_name?: string;
    bankCode?: string;
    bankType?: string;
    branchName?: string;
    cardNumberLast4?: number;
    cardType?: string;
    city?: string;
    connectorId?: number;
    countryCode?: string;
    created_date?: string;
    currency: string;
    email?: string;
    expiryDate?: string;
    expMonth?: number;
    expYear?: number;
    firstName?: string;
    identificationType?: string;
    identificationValue?: string;
    idNumber?: string;
    instrumentType?: number;
    internalBankCode?: string;
    lastName?: string;
    payment_instrument_id?: number;
    phoneNumber?: string;
    plaidEligible?: boolean;
    plaidLink?: boolean;
    requestReference?: string;
    residencyNumber?: string;
    routing_reference?: string;
    source?: number;
    state?: string;
    status?: number;
    swift?: string;
    updated_date?: string;
    userType?: string;
    uuid: string;
    verificationType?: string;
    zipCode?: string;
    canEdit: boolean
}

export interface PaymentDetails {
    saveCard: boolean;
    showIframe: boolean;
    wptarget: string;
    paymentInstrumentUuid?: string;
    connector?: string | null;
    paymentChannel?: string | null;
    cardDetails?: {
        cardNumber: string;
        cardHolderName: string;
        cardExpiryMonth: number;
        cardExpiryYear: number;
        cardSecurityCode: string;
    };
    applyClientCredit?: boolean;
    clientCreditDetails?: {
        accountNumber: string;
        amount: number;
        newAmount?:number;
    };
    applyuWallet?: boolean;
    uwalletDetails?: {
        accountNumber: string;
        amount: number;
        newAmount?:number;
    };
    applyuWalletFunding?: boolean;
    uwalletFundingDetails?: {
        accountNumber: string;
        amount: number;
        newAmount?:number;
    };
    applyuWalletTransfer?: boolean;
    uwalletTransferDetails?: {
        accountNumber: string;
        amount: number;
        newAmount?:number;
    };
    applyuWalletQC?: boolean;
    uwalletQCDetails?: {
        accountNumber: string;
        amount: number;
        newAmount?:number;
    };
    skipAuth?: string;
    successUrl?: string;
    failUrl?: string;
}

interface CheckoutResponse {
    redirectUrl: string;
    status: number; 
    tossPayClientKey?: string;
    tossPayNoAuthClientKey?: string;
    tossPayReference?: string;
    tossPayAmount?: string;
}

export interface EditPaymentRequest { 
    uuid: string;
    expiryMonth: string;
    expiryYear: string;
}

let paymentInstruments: PaymentInstrument[] | null = null;

export const resetPayment = () => {
    paymentInstruments = null;
};

// TODO: use this enum instead of the below functions
// enum PaymentInstrumentType {
//     BANK = 1,
//     CARD = 2
// }

const paymentInstrumentId = (type: 'CARD' | 'BANK') => {
    switch(type) {
        case 'CARD':
            return 2;
        case 'BANK':
            return 1;
    }
};

const paymentInstrumentEnum= (type: 1 | 2) => {
    switch(type) {
        case 1:
            return 'BANK';
        case 2:
            return 'CARD';
    }
};

const getPaymentData = (paymentObj: PaymentInstrument): PaymentInstrument => {
    const paymentFormatted: PaymentInstrument = {
        uuid: paymentObj.uuid,
        currency: paymentObj.currency,
        type: paymentInstrumentEnum(paymentObj.instrumentType as 1 | 2),
        canEdit: (paymentObj.connectorId === 16) ? true : false
    };
    if (paymentFormatted.type === 'CARD') {
        paymentFormatted.cardType = paymentObj.cardType;
        paymentFormatted.expiryDate = `${paymentObj.expMonth} / ${paymentObj.expYear}`;
        paymentFormatted.cardNumberLast4 = paymentObj.cardNumberLast4;
    } else if (paymentFormatted.type === 'BANK') {
        paymentFormatted.accountName = paymentObj.accountName;
        paymentFormatted.accountType = paymentObj.accountType;
        paymentFormatted.account_reference = paymentObj.account_reference;
    }
    return paymentFormatted;
};

export interface PaymentInstrumentsFilters {
    type: 'CARD' | 'BANK';
}

interface PaymentInstrumentsFiltersInternal extends PaymentInstrumentsFilters {
    isInternal: boolean;
}

export const getPaymentInstruments = async (filters: PaymentInstrumentsFilters): Promise<PaymentInstrument[]> => {
    return getPaymentInstrumentsInternal({ ...filters, isInternal: false });
};

export const getPaymentInstrumentsInternal = async (filters: PaymentInstrumentsFiltersInternal): Promise<PaymentInstrument[]> => {
    if(paymentInstruments && paymentInstruments.length > 0) {
        return (filters?.isInternal) ? paymentInstruments : paymentInstruments.map(data => getPaymentData(data)) ?? [];
    }
    const reqBody = await config.setGetBodyParams({ instrument_type: `${paymentInstrumentId(filters.type)}`, status: '1', size: '100' });
    const result = await httpClient.get<{ data?: { results: PaymentInstrument[] }, status: number, error?: { message: string } }>(`consumers/payment-instrument?${reqBody}`);
    if(result?.status === 1) {
        paymentInstruments = result?.data?.results.filter(inst => inst.status == 1) ?? null;
        return ((filters?.isInternal) ? paymentInstruments : result?.data?.results?.filter(inst => inst.status == 1)?.map(data => getPaymentData(data))) ?? [];
    } else {
        throw new Error(result?.error?.message);
    }
};

export const updatePaymentInstrument = async(details: EditPaymentRequest): Promise<'CARD_UPDATED_SUCCESS' | 'CARD_UPDATE_FAILED'> => {
    const requestBody = {
        'paymentInstrumentUuid' : details?.uuid,
        'expiryMonth' : details.expiryMonth,
        'expiryYear' : details.expiryYear,
    };
    const result = await httpClient.post<{ data?: unknown, status: number, error?: { message: string } }>('consumers/edit-worldpay-payment-instrument', requestBody);
    if(result.status === 1) {
        resetPayment();
        getPaymentInstrumentsInternal({type: 'CARD', isInternal: true});
        return 'CARD_UPDATED_SUCCESS';
    } else {
        return 'CARD_UPDATE_FAILED';
    }
};

const getPaymentCheckoutMode = (details: PaymentDetails) => {
    if ((details.applyClientCredit || details.applyuWallet || details.applyuWalletFunding || details.applyuWalletTransfer || details.applyuWalletQC) && (details?.paymentInstrumentUuid || details?.connector)) {
        return 3;
    } else if (details.applyClientCredit || details.applyuWallet || details.applyuWalletFunding || details.applyuWalletTransfer || details.applyuWalletQC) {
        return 2;
    } else {
        return 1;
    }
};

const paymentRequestBodyMap = async(details: PaymentDetails) => {
    let selectedChannel: FormattedConnector | null = null;
    let selectedPaymentInstrument: PaymentInstrument | null = null;
    const userDetails = await auth.getUserDetails();
    const kountDetails = await config.kountInitialize(userDetails);

    // find selectedPaymentChannel and Connector=========
    if(details?.connector) {
        const formattedChannels = await common.getPaymentConnectors();
        selectedChannel = formattedChannels.find(data => (data.displayName == details.connector && data.paymentChannel == details.paymentChannel)) ?? null;
    }

    // find selectedPaymentInstrument and Connector==================
    if(details?.paymentInstrumentUuid) {
        const paymentInstruments = await getPaymentInstrumentsInternal({type: 'CARD', isInternal: true});
        selectedPaymentInstrument = paymentInstruments.find(data => data.uuid ==  details.paymentInstrumentUuid) ?? null;
        
        const formattedChannels = await common.getPaymentConnectors();
        selectedChannel = formattedChannels.find(data => data.id == selectedPaymentInstrument?.connectorId) ?? null;
    }

    if((details.applyClientCredit || details.applyuWallet || details.applyuWalletFunding || details.applyuWalletTransfer || details.applyuWalletQC) && !details.connector && !details.paymentInstrumentUuid) {
        selectedChannel = { id: 1, paymentChannelId: 1};
    }
    
    return {
        deviceType: await config.getDeviceInfo(),
        deviceOS: await config.getDeviceOs(),
        needBalanceCheck: true,
        order_token: await auth.getOrderToken(),
        kountSessionId: kountDetails?.sessionID,
        deviceInformation: kountDetails?.machineNumber,
        connectorId: selectedChannel?.id,
        paymentChannelId: selectedChannel?.paymentChannelId,
        paymentInstrumentId: selectedPaymentInstrument?.payment_instrument_id,

        clientCreditId: (details.applyClientCredit) ? details?.clientCreditDetails?.accountNumber : null,
        fiatAccountNumber: (details.applyuWallet) ? details?.uwalletDetails?.accountNumber : null,
        loyaltyAccountNumber: (details.applyuWalletFunding) ? details?.uwalletFundingDetails?.accountNumber  : null,
        utransferAccountNumber: (details.applyuWalletTransfer) ? details?.uwalletTransferDetails?.accountNumber  : null,
        qulifyingCreditAccountNumber: (details.applyuWalletQC) ? details?.uwalletQCDetails?.accountNumber  : null,

        maxClientCredit: (details.applyClientCredit) ? details?.clientCreditDetails?.newAmount : null,
        fiatMaxAmount: (details.applyuWallet) ? details?.uwalletDetails?.newAmount : null,
        loyaltyMaxAmount: (details.applyuWalletFunding) ? details?.uwalletFundingDetails?.newAmount  : null,
        utransferMaxAmount: (details.applyuWalletTransfer) ? details?.uwalletTransferDetails?.newAmount  : null,
        qulifyingCreditMaxAmount: (details.applyuWalletQC) ? details?.uwalletQCDetails?.newAmount  : null,

        paymentCheckoutMode: getPaymentCheckoutMode(details)
    };
};

const validateRequestBody = async(requestBody: PaymentDetails): Promise<{status: boolean, message: string} | PaymentDetails> => {
    let totalWalletBalanceUsed = 0;
    const wallets = await getWalletAccounts();
    const orderDetails = await common.getOrderDetails();
    if(requestBody?.applyClientCredit) {
        if(requestBody.clientCreditDetails) {
            const walletAccount = wallets?.find(data => data.walletType === 'CLIENT_CREDIT');
            if(walletAccount) {
                const amountUsable = (requestBody?.clientCreditDetails?.amount) ? (Number(requestBody?.clientCreditDetails?.amount)) : ((orderDetails?.totalAmount > walletAccount?.balance) ? walletAccount?.balance : orderDetails?.totalAmount);
                totalWalletBalanceUsed = totalWalletBalanceUsed + amountUsable;
                requestBody.clientCreditDetails.newAmount = amountUsable;
            }
        } else {
            return {status: false, message: 'WALLET_DETAILS_MISSING'};
        }
    }

    if(requestBody?.applyuWallet) {
        if(requestBody.uwalletDetails) {
            const walletAccount = wallets?.find(data => data.walletType === 'UWALLET');
            if(walletAccount) {
                const amountUsable = (requestBody?.uwalletDetails?.amount) ? (Number(requestBody?.uwalletDetails?.amount)) : ((orderDetails?.totalAmount > walletAccount?.balance) ? walletAccount?.balance : orderDetails?.totalAmount);
                totalWalletBalanceUsed = totalWalletBalanceUsed + amountUsable;
                requestBody.uwalletDetails.newAmount = amountUsable;
            }
        } else {
            return {status: false, message: 'WALLET_DETAILS_MISSING'};
        }
    }

    if(requestBody?.applyuWalletFunding) {
        if(requestBody.uwalletFundingDetails) {
            const walletAccount = wallets?.find(data => data.walletType === 'UWALLET_FUNDING');
            if(walletAccount) {
                const amountUsable = (requestBody?.uwalletFundingDetails?.amount) ? (Number(requestBody?.uwalletFundingDetails?.amount)) : ((orderDetails?.totalAmount > walletAccount?.balance) ? walletAccount?.balance : orderDetails?.totalAmount);
                totalWalletBalanceUsed = totalWalletBalanceUsed + amountUsable;
                requestBody.uwalletFundingDetails.newAmount = amountUsable;
            }
        } else {
            return {status: false, message: 'WALLET_DETAILS_MISSING'};
        }
    }

    if(requestBody?.applyuWalletTransfer) {
        if(requestBody.uwalletTransferDetails) {
            const walletAccount = wallets?.find(data => data.walletType === 'UWALLET_TRANSFER');
            if(walletAccount) {
                const amountUsable = (requestBody?.uwalletTransferDetails?.amount) ? (Number(requestBody?.uwalletTransferDetails?.amount)) : ((orderDetails?.totalAmount > walletAccount?.balance) ? walletAccount?.balance : orderDetails?.totalAmount);
                totalWalletBalanceUsed = totalWalletBalanceUsed + amountUsable;
                requestBody.uwalletTransferDetails.newAmount = amountUsable;
            }
        } else {
            return {status: false, message: 'WALLET_DETAILS_MISSING'};
        }
    }

    if(requestBody?.applyuWalletQC) {
        if(requestBody.uwalletQCDetails) {
            const walletAccount = wallets?.find(data => data.walletType === 'QUALIFYING_CREDIT');
            if(walletAccount) {
                const amountUsable = (requestBody?.uwalletQCDetails?.amount) ? (Number(requestBody?.uwalletQCDetails?.amount)) : ((orderDetails?.totalAmount > walletAccount?.balance) ? walletAccount?.balance : orderDetails?.totalAmount);
                totalWalletBalanceUsed = totalWalletBalanceUsed + amountUsable;
                requestBody.uwalletQCDetails.newAmount = amountUsable;
            }
        } else {
            return {status: false, message: 'WALLET_DETAILS_MISSING'};
        }
    }

    if(totalWalletBalanceUsed > orderDetails?.totalAmount) {
        return {status: false, message: 'AMOUNT_GREATER_THAN_ORDER_AMOUNT'}; 
    }

    if(!requestBody.applyClientCredit && !requestBody.applyuWallet && !requestBody.applyuWalletFunding && !requestBody.applyuWalletTransfer && !requestBody.applyuWalletQC && !requestBody?.paymentInstrumentUuid && !requestBody?.connector) {
        return {status: false, message: 'PAYMENT_METHODS_NOT_FOUND'}; 
    }

    if(requestBody?.paymentInstrumentUuid && requestBody?.connector) {
        return {status: false, message: 'INAVLID_PAYMENT_COMBINATIONS'}; 
    }

    if((totalWalletBalanceUsed < orderDetails?.totalAmount) && !requestBody?.paymentInstrumentUuid && !requestBody?.connector) {
        return {status: false, message: 'INAVLID_PAYMENT_COMBINATIONS'};
    }

    if((totalWalletBalanceUsed === orderDetails?.totalAmount)) {
        requestBody.connector = null;
    }

    return requestBody;
};

const checkForDuplicateCard  = async(cardDetails: PaymentDetails['cardDetails']): Promise<boolean> => {
    const reqBody = {
        cardNumber: cardDetails?.cardNumber?.slice(-4),
        expiryMonth: Number(cardDetails?.cardExpiryMonth),
        expiryYear: Number(cardDetails?.cardExpiryYear?.toString().padStart(4, '20')),
    };
    const result = await httpClient.post<httpClient.ApiResponse<boolean>>('transaction/validate-payment-instrument', reqBody);
    if(result?.status === 1) {
        return false;
    } else {
        return true; 
    }
};

export const submitPayment = async(details: PaymentDetails) => {

    const isRequestBodyValid = await validateRequestBody(details);

    if('status' in isRequestBodyValid && !isRequestBodyValid.status) {
        throw new Error(isRequestBodyValid.message);
    }

    if(details.saveCard) {
        await common.saveCard(details.saveCard);
    }

    if(details.connector === 'WORLDPAY' && details.cardDetails) {
        const isDuplicate = await checkForDuplicateCard(details.cardDetails);
        if (isDuplicate) {
            return { message: 'DUPLICATE_CARD_DETAILS' };
        }
    }

    const requestBody = await paymentRequestBodyMap(isRequestBodyValid as PaymentDetails);
    const result = await httpClient.post<httpClient.ApiResponse<CheckoutResponse>>('transaction/checkout', requestBody);
    if(result?.status === 1) {
        const userDetails = await auth.getUserDetails();
        const orderDetails = await common.getOrderDetails();
        if(details.connector === 'WORLDPAY' && result.data?.redirectUrl) {
            if (!details.cardDetails && !details.showIframe) {
                throw new Error('CARD_DETAILS_REQUIRED');
            } else if (details.showIframe && !details.wptarget) {
                throw new Error('WP_TARGET_REQUIRED');
            } else if (details.showIframe && details.wptarget) {
                const reqOptions: wpConnector.WorldPayOptions =  { 
                    wpUrl: result.data?.redirectUrl,
                    showIframe: details.showIframe,
                    target: details?.wptarget
                };
                const connectorResult = await wpConnector.addWorldPayCard(orderDetails?.currency, reqOptions);
                if(connectorResult === 'card_added_successful') {
                    return { message: 'PAYMENT_SUCCESSFUL' };
                } else {
                    return { message: 'PAYMENT_FAILED' };
                }
            } else if (details.cardDetails) {
                const reqOptions: wpConnector.WorldPayOptions =  { 
                    cardDetails: details.cardDetails, 
                    wpUrl: result.data?.redirectUrl,
                    showIframe: false,
                    target: details?.wptarget ?? null
                };
                const connectorResult = await wpConnector.addWorldPayCard(orderDetails?.currency, reqOptions);
                if(connectorResult === 'card_added_successful') {
                    return { message: 'PAYMENT_SUCCESSFUL' };
                } else {
                    return { message: 'PAYMENT_FAILED' };
                }
            } else {
                throw new Error('REQUIRED_DETAIL_MISSING');
            } 
        } else if(details.connector === 'TOSS_PAYMENT_INSTALMENT' || details.connector === 'TOSS_PAYMENT_INSTALMENT_NO_AUTH') {
            const options = {
                tossPayKey: (details.connector === 'TOSS_PAYMENT_INSTALMENT') ? result?.data?.tossPayClientKey : result?.data?.tossPayNoAuthClientKey,
                orderId: result?.data?.tossPayReference ?? orderDetails.orderReference,
                customerName: userDetails?.first_name.concat(userDetails?.last_name),
                customerEmail: userDetails?.email_address,
                amount: Number(result?.data?.tossPayAmount),
                orderName: result?.data?.tossPayReference?.concat('-name') ?? `${orderDetails.orderReference}-name`,
                successUrl: details?.successUrl ?? window.location.origin,
                failUrl: details?.failUrl ?? window.location.origin,
                ...(details?.skipAuth ? { _skipAuth: details.skipAuth } : {})
            };
            await tossConnector.loadTossPay(options);
            return { message: 'PAYMENT_PROCESSING' };
        } else if(result.data?.redirectUrl) {
            return { redirectUrl: result.data?.redirectUrl, message: 'PAYMENT_PENDING' };
        } else if (result.data.status == 99){ 
            return { message: 'PAYMENT_FAILED' };
        } else {
            return { message: 'PAYMENT_SUCCESSFUL' };
        }
    } else {
        throw new Error(result?.error?.message);
    }
};


