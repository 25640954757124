import { default as axios } from 'axios';
import { getConfig } from './config';
import { getToken, getOrderToken } from '../api/authentication';

const httpClient = axios.create({
    headers: { 'Content-Type': 'application/json' }
});

// TODO?: Create a wrapper function to handle error responses & return the data directly
export interface ApiResponse<T> {
    data: T;
    status: number;
    error?: { message: string };
}

httpClient.interceptors.request.use((axiosConfig) => {
    const sdkConfig = getConfig();
    axiosConfig.baseURL = sdkConfig.apiBaseUrl;
    const token = getToken();
    axiosConfig.headers = axiosConfig.headers || {};
    if (token && axiosConfig.url.indexOf('order/uuid/login') < 0) {
        axiosConfig.headers.Authorization = `Token ${token}`;
    } else {
        const orderToken = getOrderToken();
        axiosConfig.headers.Authorization = `Token ${orderToken}`;
    }
    return axiosConfig;
}, (error) => {
    return Promise.reject(error);
});

// TODO: Let API calls throw errors
const post = async<T>(endpoint: string, data: unknown, headers = {}): Promise<T> => {
    try {
        const response = await httpClient.post<T>(endpoint, data, { headers });
        return response.data as T;
    } catch (error: any) {
        return error.response?.data || error.message;
    }
};

const get = async<T>(endpoint: string, headers = {}): Promise<T> => {
    try {
        const response = await httpClient.get<T>(endpoint, { headers });
        return response.data as T;
    } catch (error: any) {
        return error.response?.data || error.message;
    }
};

export { post, get };