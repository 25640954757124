import * as httpClient from '../utils/httpClient';

export interface UserDetails {
    address1: string;
    address2: string | null;
    businessName: string;
    city: string;
    client_uuid: string;
    country: string;
    distributorId: string;
    dob: string | null;
    email_address: string;
    first_name: string;
    hkcm: boolean;
    isMaskedEmail: boolean;
    kountEnabled: string;
    kountEnvironment: string;
    kountMerchantId: string;
    kountScore: number | null;
    kycVerified: boolean | null;
    last_login_time: string | null;
    last_name: string;
    locale: string;
    password_expired: boolean;
    payquickerFlex: boolean;
    phone_number: string;
    role: string;
    saveCard: boolean;
    sso_enabled: boolean;
    state: string;
    timezoneId: number;
    zipcode: string;
}

let accessToken: string | null = null;
let orderToken: string | null = null;
let userDetails: UserDetails | null = null;

export const login = async (credentials: { uuid: string }) => {
    const result = await httpClient.post<httpClient.ApiResponse<{ access_token: string }>>('order/uuid/login', credentials);
    if(result?.status === 1) {
        return setToken(result.data.access_token);
    } else {
        throw new Error(result?.error?.message);
    }
};

export const setOrderToken = (token: string) => {
    orderToken = token;
    return true;
};

export const getToken = () => accessToken;

// For experimental purposes only
export const setToken = (token: string) => {
    accessToken = token;
    return true;
};

export const getOrderToken = () => orderToken;

export const getUserDetails = async() => {
    if(userDetails) { return userDetails; }
    const [result, result2] = await Promise.all([
        httpClient.get<httpClient.ApiResponse<UserDetails>>('consumer/profile'),
        httpClient.get<httpClient.ApiResponse<UserDetails>>('consumers/profile')
    ]);
    if(result?.status === 1 && result2?.status === 1) {
        userDetails = { ...result?.data ?? {}, ...result2?.data ?? {}};
        return userDetails;
    } else {
        throw new Error(result?.error?.message);
    }
};


export const killSession = () => {
    accessToken = null;
    orderToken = null;
    userDetails = null;
}; 