import kountSDK from '@kount/kount-web-client-sdk';
import FingerprintJS from '@fingerprintjs/fingerprintjs';


export interface Config {
    apiBaseUrl: string;
}

let SDKConfig: Config = {
    apiBaseUrl: 'https://payment.sandbox.u-payments.co'
};

export const getConfig = () => ({ ...SDKConfig });

export const setConfig = (newConfig: Config) => {
    SDKConfig = { ...SDKConfig, ...newConfig };
};

export const setGetBodyParams = (reqObj: Record<string, string>) => {
    return Object.keys(reqObj).map(data => `${data}=${reqObj[data]}`).join('&');
};

export const getSessionIDorGenerateOne = () => {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 32; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
    }
    return result;
};

export interface KountDetails {
    kountEnabled: string;
    kountEnvironment?: string;
    kountMerchantId?: string;
}
interface KountConfig {
    isSinglePageApp: boolean;
    environment: string;
    clientID: string;
    callbacks?: {
        'collect-end': (params: { SessionID?: string }) => void;
    };
}

export const kountInitialize = async (kountDetails: KountDetails): Promise<{ sessionID: string | null, machineNumber: string | null }> => {
    if (kountDetails.kountEnabled === 'true') {
        // TODO: Fix this https://eslint.org/docs/latest/rules/no-async-promise-executor
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const kountConfig: KountConfig = {
                isSinglePageApp: true,
                environment: kountDetails?.kountEnvironment ?? 'TEST',
                clientID: kountDetails?.kountMerchantId ?? '700019',
            };
            
            let machineNumber: string | null = null;
            const fpPromise = await FingerprintJS.load().then(fp => fp.get()).then(result => {
                machineNumber = result.visitorId;
            }).catch((error: unknown) => {
                console.log(fpPromise);
                console.error('Error retrieving fingerprint:', error);
            });
            kountConfig.callbacks = {
                'collect-end': function (params) {
                    resolve({ sessionID: params?.SessionID ?? null, machineNumber: machineNumber ?? null});
                }
            };
            const sessionID = await getSessionIDorGenerateOne();
            kountSDK(kountConfig, sessionID);
        });
    } else {
        return { sessionID: null, machineNumber: null};
    }
};

export const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    if (/mobile/i.test(userAgent)) {
        return 1;
    } else if (/tablet/i.test(userAgent)) {
        return 3;
    } else if (/console/i.test(userAgent)) {
        return 2;
    } else {
        return 0;
    }
};

export const getDeviceOs = () => {
    const userAgent = navigator.userAgent;
    let osInfo = 'other';
    if (/windows/i.test(userAgent)) {
        osInfo = 'windows';
    } else if (/macintosh|mac os x/i.test(userAgent)) {
        osInfo = 'osx';
    } else if (/linux/i.test(userAgent)) {
        osInfo = 'linux';
    } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        osInfo = 'ios';
    } else if (/android/i.test(userAgent)) {
        osInfo = 'android';
    }
    return osInfo;
};