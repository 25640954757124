import * as Common from '../common';
import * as Payment from '../payment';
import * as httpClient from '../../utils/httpClient';

interface GetApiKeyResponse {
    nmiPublicKey: string;
    stripePublicKey: string;
}

declare global {
    interface Window {
        CollectJS: {
            configure: (config: NMICollectJSConfig) => void;
            startPaymentRequest: () => void;
        };
    }
}

export interface CollectJSConfig {
    fields: {
        ccnumber: CollectJSField;
        ccexp: CollectJSField;
        cvv: CollectJSField;
    };
    customCss?: Partial<CSSStyleDeclaration>;
    placeholderCss?: Partial<CSSStyleDeclaration>;
    validationCallback?: (field: string, status: boolean, message?: string) => void;
    callback: (string: 'CARD_ADDED_SUCCESS' | 'CARD_ADD_FAILED') => void;
}


export interface NMICollectJSConfig {
    variant: 'inline';
    styleSniffer?: boolean;
    fields: {
        ccnumber: CollectJSField;
        ccexp: CollectJSField;
        cvv: CollectJSField;
    };
    customCss?: Partial<CSSStyleDeclaration>;
    placeholderCss?: Partial<CSSStyleDeclaration>;
    validationCallback?: (field: string, status: boolean, message?: string) => void;
    callback: (token: AddNMIRequest) => void;
}

export interface AddPaymentInstrumentResponse {
    paymentInstrumentId: number,
    paymentInstrumentUuid: string,
    status: number,
    currency: string,
}

interface CollectJSField {
    placeholder: string;
    selector: string;
}

export interface NewCardRequest {
    cardHolderName: string | null;
    cardType: string;
}

interface AddNMIRequest {
    token: string;
    connector: string;
    card: {
        number: string;
        exp: string;
        type: string;
        bin?: string;
    };
}


let cardHolderName: string | null = null;
let linkElement: HTMLScriptElement | null = null;

export const loadNMICard = async(options: CollectJSConfig): Promise<'NMI_LOADED' | 'NMI_LOAD_FAILED'> => {
    if (linkElement) {
        linkElement.remove();
        linkElement = null;
    }
    const apikey = await getSaveCardKey();
    if(!apikey) {
        return 'NMI_LOAD_FAILED';
    }
    return new Promise((resolve, reject) => {
        
        linkElement = document.createElement('script');
        linkElement.setAttribute('id', 'nmiScript');
        linkElement.src = 'https://secure.safewebservices.com/token/Collect.js';
        linkElement.setAttribute('data-tokenization-key', apikey);
        document.head.appendChild(linkElement);

        linkElement.onload = async () => {
            try {
                if (window?.CollectJS) {
                    window.CollectJS.configure({
                        variant: 'inline',
                        styleSniffer: true,
                        fields: options.fields,
                        customCss: options.customCss,
                        placeholderCss: options.placeholderCss,
                        validationCallback: options.validationCallback,
                        callback: async (response: AddNMIRequest) => {
                            const result: 'CARD_ADDED_SUCCESS' | 'CARD_ADD_FAILED' = await addCardAndSubmit(response);
                            options.callback(result);
                        },
                    });
                    resolve('NMI_LOADED');
                } else {
                    reject(new Error('CollectJS is not available'));
                }
            } catch (error) {
                reject(error);
            }
        };
    });
};

export const addNMICard = (request: NewCardRequest) => {
    cardHolderName = request?.cardHolderName;
    window.CollectJS.startPaymentRequest();
};

const addCardAndSubmit = async (details: AddNMIRequest): Promise<'CARD_ADDED_SUCCESS' | 'CARD_ADD_FAILED'> => {
    const OrderDetails = await Common.getOrderDetails();
    const formattedChannels = await Common.getPaymentConnectors();
    const selectedChannel = formattedChannels.find(data => data.displayName === 'NMI') ?? null;
    const requestBody = {
        instrumentType: 2,
        orderReference: OrderDetails.orderReference,
        invoiceNumber: OrderDetails.invoiceNumber,
        cardDetail: {
            currency: OrderDetails.currency,
            cardId: details.token,
            connectorId: selectedChannel?.id,
            last4: details.card.number.slice(-4),
            expire: details.card.exp,
            cardType: details.card.type,
            binNumber: details?.card?.bin,
            cardHolderName: cardHolderName,
        },
    };
    const result = await httpClient.post<{ data?: AddPaymentInstrumentResponse; status: number; error?: { message: string } }>( 'consumers/payment-instrument', requestBody);
    if (result.status === 1) {
        Payment.resetPayment();
        Payment.getPaymentInstrumentsInternal({ type: 'CARD', isInternal: true });
        return 'CARD_ADDED_SUCCESS';
    } else {
        return 'CARD_ADD_FAILED';
    }
};


const getSaveCardKey = async () => {
    const result = await httpClient.get<httpClient.ApiResponse<GetApiKeyResponse>>('transaction/payment/apikey');
    if (result?.status === 1) {
        return result?.data?.nmiPublicKey;
    } else {
        return false;
    }
};