import * as Common from '../common';
import * as Payment from '../payment';
import * as httpClient from '../../utils/httpClient';
import type { AddPaymentInstrumentResponse } from './CardNmi';

interface RapydKeyResponse {
    'token': string;
    'url': string;
}

interface RapydResponse {
    id: string;
    bin_details?: {
        bin_number: string;
    };
    last4: string;
    expiration_month: string;
    expiration_year: string;
    type: string;
    name: string;
}

interface LoadingEventDetail {
    loading: boolean;
}

export interface Styling {
    styles: { [key: string]: string };
}

declare global {
    interface WindowEventMap {
        onSaveCardDetailsSuccess: CustomEvent<{ 
            next_action?: string;
            redirect_url?: string;
            payment_method_data?: RapydResponse;
        }>;
        onSaveCardDetailsFailure: CustomEvent<unknown>;
    }
}

interface RapydToolkitOptions {
    pay_button_text?: string;
    pay_button_color?: string;
    id: string;
    close_on_complete?: boolean;
    page_type?: 'card_token' | 'checkout' | 'other_supported_type';
    style?: Record<string, unknown>; // Adjust type based on actual structure of `rapydDetails.styles`
}


declare class RapydToolkit {
    constructor(options: RapydToolkitOptions);
    displayToolkit(): void;
}
let rapydCardResponseDetails: RapydResponse | null = null;
let linkElement: HTMLScriptElement | null = null;


const getRapydCardToken = async (currency: string): Promise<RapydKeyResponse | null> => {
    const result = await httpClient.get<httpClient.ApiResponse<RapydKeyResponse>>(`transaction/card/connector-token?currency=${currency}`);
    if (result?.status === 1) {
        return result?.data;
    } else {
        return null;
    }
};

export const loadRapydframe = async(rapydDetails: Styling) => {

    const orderDetails = await Common.getOrderDetails();
    const rapydInfo = await getRapydCardToken(orderDetails.currency);

    return new Promise((resolve, reject) => {
        if(rapydInfo?.token && rapydInfo?.url) {
            linkElement = document.createElement('script');
            linkElement.setAttribute('id', 'rapydScriptId');
            linkElement.src = rapydInfo?.url;
            document.head.appendChild(linkElement);
    
            linkElement.onload = async () => {
                try {
                    const checkout = new RapydToolkit({
                        pay_button_text: 'Save',
                        pay_button_color: 'blue',
                        id: rapydInfo.token,
                        close_on_complete: true,
                        page_type: 'card_token',
                        style: rapydDetails.styles,
                    });
                    checkout?.displayToolkit();
                    resolve('RAPYD_INIT_SUCCESS');
                } catch (error) {
                    console.log(error);
                    reject('RAPYD_INIT_FAILED');
                }
            };
        }  
    });
};

const addCardAndSubmit = async () => {
    const OrderDetails = await Common.getOrderDetails();
    const formattedChannels = await Common.getPaymentConnectors();
    const selectedChannel = formattedChannels.find(data => data.displayName === 'RAPYD') ?? null;
    const requestBody = {
        instrumentType: 2,
        orderReference: OrderDetails.orderReference,
        invoiceNumber: OrderDetails.invoiceNumber,
        'cardDetail': {
            'cardId': rapydCardResponseDetails?.id,
            'binNumber': rapydCardResponseDetails?.bin_details?.bin_number,
            'connectorId': selectedChannel?.id,
            'last4': rapydCardResponseDetails?.last4,
            'expire': (rapydCardResponseDetails) ? rapydCardResponseDetails?.expiration_month + rapydCardResponseDetails?.expiration_year : null,
            'cardType': rapydCardResponseDetails?.type,
            'cardHolderName': rapydCardResponseDetails?.name,
            'currency': OrderDetails?.currency
        }
    };

    const result = await httpClient.post<{ data?: AddPaymentInstrumentResponse; status: number; error?: { message: string } }>( 'consumers/payment-instrument', requestBody);
    if (result.status === 1) {
        Payment.resetPayment();
        Payment.getPaymentInstrumentsInternal({ type: 'CARD', isInternal: true });
        sendPostMessage('CARD_ADDED_SUCCESS');
    } else {
        sendPostMessage('CARD_ADD_FAILED');
    }
};

const SaveCardDetailsSuccess = (event: CustomEvent<{ next_action?: string; redirect_url?: string; payment_method_data?: RapydResponse }>) => {
    if (event.detail.next_action === '3d_verification' && event?.detail?.payment_method_data) {
        rapydCardResponseDetails = event?.detail?.payment_method_data;
        window.parent.postMessage({ message: 'REDIRECT_3DS', redirectUrl: event.detail.redirect_url }, window.location.origin);
        window.removeEventListener('onSaveCardDetailsSuccess', SaveCardDetailsSuccess);
        return;
    }
    if(event?.detail?.payment_method_data) {
        rapydCardResponseDetails = event?.detail?.payment_method_data;
        window.removeEventListener('onSaveCardDetailsSuccess', SaveCardDetailsSuccess);
        addCardAndSubmit();
    } else {
        sendPostMessage('CARD_ADD_FAILED');
    }
};

const SaveCardDetailsFailure = (event: CustomEvent) => {
    console.log(event);
    window.removeEventListener('onSaveCardDetailsFailure', SaveCardDetailsFailure);
    sendPostMessage('CARD_ADD_FAILED');
};

const RapydSuccess = (event: MessageEvent) => {
    if (event.data === 'rapyd_secure_success') {
        window.removeEventListener('message', RapydSuccess);
        addCardAndSubmit();
    }
};

const sendPostMessage = (message: string) => {
    window.parent.postMessage({ message: message }, window.location.origin);
};

window.addEventListener('onLoading', (event: Event) => {
    const customEvent = event as CustomEvent<LoadingEventDetail>;
    if(customEvent && customEvent.detail) {
        window.parent.postMessage({message: 'LOADING', isLoading: customEvent?.detail?.loading}, window.location.origin);
    }
});

window.addEventListener('onSaveCardDetailsSuccess', SaveCardDetailsSuccess as EventListener);
window.addEventListener('onSaveCardDetailsFailure', SaveCardDetailsFailure as EventListener);
window.addEventListener('message', RapydSuccess as EventListener);


