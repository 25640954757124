import { getOrderToken } from './authentication';
import * as httpClient from '../utils/httpClient';
import { setGetBodyParams } from '../utils/config';


export interface OrderDetails {
    invoiceNumber: string;
    totalAmount: number;
    tipAmount: number | null;
    exciseTax: number | null;
    salesTax: number | null;
    serviceFee: number | null;
    currency: string;
    cancelUrl: string;
    redirectUrl: string;
    tipAllowed: boolean;
    paymentType: string | null;
    paymentMode: string | null;
    toVirtualCode: string;
    merchantName: string;
    language: string;
    enableCardPayment: boolean;
    autoshipRequired: boolean;
    orderReference: string;
    orderRecipient: string | null;
}

export interface WalletAccount {
    walletType: string;
    accountName: string;
    accountNumber: string;
    balance: number;
    currency: string;
}

export interface AccountResponse {
    available_balance: number;
    accountType: number;
    account_name: string;
    account_number: string;
    currency: string;
}

export interface FormattedConnectors {
    id: number;
    connectors: FormattedConnector[];
    displayName: string;
}

export interface FormattedConnector {
    id: number;
    displayName?: string;
    paymentChannel?: string;
    paymentChannelId: number;
}

export interface PaymentChannel {
    id: number;
    displayName: string;
    needLoadFund: boolean;
    connectors: {
        id: number,
        name: string,
        displayName: string,
        autoShipConfig: boolean
    }[];
}

export interface CancelResponse { 
    status: string;
    redirectUrl: string;
}

// TODO: Figure out the structures for the unknown types

let orderDetails: OrderDetails | null = null;
let walletAccounts: WalletAccount[] | null = null;
let payquikcerBalance: number | null = null;
let availablePaymentChannels: PaymentChannel[] | null = null;

const getWalletEnum = (type: number) => {
    switch(type) {
        case 1:
            return 'UWALLET';
        case 2:
            return 'UWALLET_FUNDING';
        case 3:
            return 'UWALLET_TRANSFER';
        case 5:
            return 'QUALIFYING_CREDIT';
    }
};

export const resetCommon = () => {
    orderDetails = null;
    walletAccounts = null;
    payquikcerBalance = null;
    availablePaymentChannels = null;
};

export const getOrderDetails = async () => {
    if(orderDetails) {
        return orderDetails;
    }
    const result = await httpClient.get<httpClient.ApiResponse<OrderDetails>>(`redirect/v1/${getOrderToken()}`);
    if(result?.status === 1) {
        orderDetails = result.data;
        return orderDetails;
    } else {
        throw new Error(result?.error?.message);
    }
};

// For experimental purposes only
export const setOrderDetails = (details: OrderDetails) => {
    orderDetails = details;
};

export const cancelPaymentOrder = async(): Promise<CancelResponse> => {
    const result = await httpClient.post<httpClient.ApiResponse<unknown>>(`redirect/cancel/${getOrderToken()}`, {});
    if(result?.status === 1) {
        return { status: 'ORDER_CANCELLED', redirectUrl: orderDetails?.redirectUrl ?? 'www.google.com' };
    } else {
        throw new Error(result?.error?.message);
    }
};

export const assignOrder = async () => {
    const result = await httpClient.get<httpClient.ApiResponse<unknown>>(`consumers/assign-order/${orderDetails?.orderReference}`);
    if(result?.status === 1) {
       console.info('Order_Assigned');
    } else {
        throw new Error(result?.error?.message);
    }
};


export const saveCard = async (saveCardCheck: boolean) => {
    const result = await httpClient.post<httpClient.ApiResponse<unknown>>('consumers/save-card', {saveCard: (orderDetails?.autoshipRequired) ? true : saveCardCheck, orderReference: orderDetails?.orderReference});
    if(result?.status === 1) {
       return true;
    } else {
        return false;
    }
};

export const checkForDuplicate = async () => {
    const result = await httpClient.get<httpClient.ApiResponse<unknown>>(`consumers/verify-duplicate-transaction/${orderDetails?.orderReference}`);
    if(result?.status === 1) {
        if(result.data) {
            return true; 
        } else {
            return false;
        }
    } else {
        throw new Error(result?.error?.message);
    }
};

export const getPayquickerBalance = async() => {
    if(payquikcerBalance) {
        return payquikcerBalance;
    }
    const result = await httpClient.get<httpClient.ApiResponse<number>>(`transaction/balance?currency=${orderDetails?.currency}`);
    if(result?.status === 1) {
        payquikcerBalance = result?.data;
        return result?.data ?? 0.00;
    } else {
        throw new Error(result?.error?.message);
    }
};

export const getWallets = async () => {
    if(walletAccounts && walletAccounts.length > 0) {
        return walletAccounts;
    }
    const currency = orderDetails?.currency ?? '';
    const reqBody = await setGetBodyParams({ currency, status: '1', size: '100' });
    const result = await httpClient.get<httpClient.ApiResponse<AccountResponse[]>>(`account?${reqBody}`);
    const result2 = await httpClient.get<httpClient.ApiResponse<{ points: number, currency: string, clientCreditId: string }>>(`transaction/client-credit?currency=${currency}`);
    if(result?.status === 1 || result2.status === 1) {
        if(result.data.length > 0) {
            walletAccounts = result.data.filter(data => (data.available_balance > 0) && (data.accountType != 5 || (data.accountType === 5 && !orderDetails?.autoshipRequired)))?.map(wallet => {
                return {
                    walletType: getWalletEnum(wallet.accountType),
                    accountName: wallet.account_name,
                    accountNumber: wallet.account_number,
                    balance: wallet.available_balance,
                    currency: wallet.currency
                } as WalletAccount;
            }) ?? [];
        }
        if(result2.data.points > 0) {
            walletAccounts?.unshift({
                walletType: 'CLIENT_CREDIT',
                accountName: 'Credit Point',
                accountNumber: result2.data.clientCreditId,
                balance: result2.data.points,
                currency: result2.data.currency
            });
        }
        return walletAccounts || [];
    } else {
        throw new Error(result?.error?.message);
    }
};

export const getPaymentConnectors = async () => {
    if(availablePaymentChannels) {
        return formattedConnectors(availablePaymentChannels);
    }
    const result = await httpClient.get<httpClient.ApiResponse<{ response: { channel: PaymentChannel }[] }>>(`consumers/connector?orderToken=${getOrderToken()}&featureId=1`);
    if(result?.status === 1) {
        if(result.data?.response?.length > 0) {
            availablePaymentChannels = result.data.response?.flatMap(data => data.channel);
            return formattedConnectors(availablePaymentChannels);
        } else {
            return [];
        }
    } else {
        throw new Error(result?.error?.message);
    }
};

export const formattedConnectors = (paymentChannels: PaymentChannel[]) => {
    const formattedPaymentChannels = paymentChannels.flatMap(channel => {
        return channel.connectors.map(connector => {
            return {
                ...connector,
                paymentChannel: channel.displayName,
                paymentChannelId: channel.id
            } as FormattedConnector;
        });
    });
    return formattedPaymentChannels;
};