interface TossPayments {
    requestPayment(method: string, params: Record<string, any>): Promise<void>;
}

let paymentMethods: TossPayments | null = null;
let hppScript: HTMLScriptElement | null = null;

export interface TossPayOptions {
    amount: number;
    orderId: string;
    orderName: string;
    customerName: string;
    customerEmail: string;
    successUrl: string;
    failUrl: string;
    tossPayKey?: string;
    _skipAuth?: string | null;
}

interface TossReqPaymentResponse {
    code: string;
    message: string;
}

declare global {
    interface Window {
        TossPayments?: (key: string) => any;
    }
}

export async function loadTossPay(tossPayOptions: TossPayOptions) {
    
    return new Promise((resolve, reject) => {
        hppScript = document.createElement('script');
        hppScript.src = 'https://js.tosspayments.com/v1/payment';
        hppScript.async = true;
        hppScript.onload = async () => {
            if (window.TossPayments && tossPayOptions.tossPayKey) {
                paymentMethods = window.TossPayments(tossPayOptions.tossPayKey);
                requestToTossPay(tossPayOptions);
                return resolve('TOSSPAY_INITIATED');
            }
        };
        hppScript.onerror = () => {
            reject('TOSSPAY_INIT_FAILED');
        };
        document.head.appendChild(hppScript);
    });
}

async function requestToTossPay(params: TossPayOptions) {
    delete params.tossPayKey;
    if(paymentMethods) {
        paymentMethods.requestPayment('CARD', params).catch(function(error: TossReqPaymentResponse) {
            if (error.code === 'USER_CANCEL') {
              sessionStorage.setItem('errorMessage', error.message);
              if(params?.failUrl) {
                window.location.href = params?.failUrl;
              }
            }
        });
    }
}