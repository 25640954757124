import * as httpClient from '../../utils/httpClient';

type WPCLResultCallback = {
    order: {
        orderKey: string;
        status: 'success' | 'failure' | 'error' | 'pending' | 'exception' | 'cancelled_by_shopper' | 'session_expired' | 'already_processed';
        error?: {
            referenceNumber?: string;
        }
        gateway: unknown;
    };
};

type WPCLFlowCallback = {
    page: string;
};

declare class WPCLLibrary {
    setup(options: {
        url: string;
        type: string;
        inject: string;
        hideContent: boolean;
        target?: string;
        accessibility?: boolean;
        debug: boolean;
        language?: string;
        country?: string;
        preferredPaymentMethod?: string;
        flowCallback: (result: WPCLFlowCallback) => void;
        resultCallback: (result: WPCLResultCallback) => void;
    }): void;
    populateForm(cardDetails: {
        cardNumber: string;
        cardHolderName: string;
        cardExpiryMonth: number;
        cardExpiryYear: number;
        cardSecurityCode: string;
    }): void;
    submitForm(): void;
    destroy(): void;
}

declare global {
    interface Window {
        WPCL: {
            Library: typeof WPCLLibrary;
        };
    }
}

export interface WorldPayOptions {  
    cardDetails?: {
        cardNumber: string;
        cardHolderName: string;
        cardExpiryMonth: number;
        cardExpiryYear: number;
        cardSecurityCode: string;
    };
    showIframe: boolean;
    wpUrl: string;
    target: string;
}

let libraryObject: WPCLLibrary | null = null;
let hppScript: HTMLScriptElement | null = null;
let iframeContainer: HTMLDivElement | null = null;

const getWorldPayUrl = async (value: string) => {
    const result = await httpClient.post<httpClient.ApiResponse<string>>('consumers/worldpay-payment-instrument/' + value, {});
    if (result?.status === 1) {
        return result.data;
    } else {
        throw new Error(result?.error?.message);
    }
};

export async function addWorldPayCard(currency: string, wpOptions: WorldPayOptions) {
    return new Promise((resolve, reject) => {
        if (!wpOptions.cardDetails && !wpOptions.showIframe && !wpOptions.target) {
            reject('CARD_DETAILS_NOT_FOUND');
            return;
        }
        if (libraryObject || hppScript || iframeContainer) {
            destroyWorldpay();
        }
        
        iframeContainer = document.createElement('div');
        iframeContainer.setAttribute('id', 'uwallet-wp-iframe');
        document.body.appendChild(iframeContainer);

        hppScript = document.createElement('script');
        hppScript.src = 'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js';
        hppScript.async = true;
        hppScript.onload = async () => {
            try {
                const result = await addCardAndSubmit(currency, wpOptions);
                resolve(result);
            } catch (error) {
                reject(error);
            }
        };
        hppScript.onerror = () => {
            reject('WORLDPAY_INIT_FAILED');
        };
        document.head.appendChild(hppScript);
    });
}

async function addCardAndSubmit(currency: string, wpOptions: WorldPayOptions) {
    if (!window.WPCL) {
        throw new Error('WP_LIBRARY_NOT_FOUND');
    }
    const worldPayUrl = (wpOptions.wpUrl) ? wpOptions.wpUrl : await getWorldPayUrl(currency);
    return new Promise((resolve) => {
        const customOptions = {
            url: worldPayUrl,
            type: 'iframe',
            target: wpOptions.showIframe ? wpOptions.target : iframeContainer?.id,
            inject: 'immediate',
            debug: false,
            hideContent: !wpOptions.showIframe,
            flowCallback: (event: WPCLFlowCallback) => {
                if (event.page === 'card-details' && wpOptions.cardDetails) {
                    libraryObject?.populateForm(wpOptions?.cardDetails);
                    libraryObject?.submitForm();
                }
            },
            resultCallback: (event: WPCLResultCallback) => {
                console.debug('WP resultCallback', event);
                const status = event.order.status;
                let postMessage;
                switch (status) {
                    case 'success':
                        postMessage = 'card_added_successful';
                        break;
                    case 'failure':
                    case 'error':
                    case 'cancelled_by_shopper':
                    case 'already_processed':
                    case 'session_expired':
                    default:
                        postMessage = 'card_add_failed';
                }
                destroyWorldpay();
                resolve(postMessage);
            },
        };
        libraryObject = new window.WPCL.Library();
        libraryObject.setup(customOptions);
    });
}

export function destroyWorldpay() {
    libraryObject?.destroy();
    iframeContainer?.remove();
    hppScript?.remove();
    libraryObject = null;
    iframeContainer = null;
    hppScript = null;
}